import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import { useEffect } from 'react';
//** Import Image */
import profile from "../../../../images/profile/profile.png";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}

  const AppProfile = () => {


      const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  
  if (auth_token !== null) {
    const params = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9', // Add your key here
      currency: selectedCurrency, // Add the currency parameter
    };

    axios.get('/home-data', { params })
      .then(response => {
          console.log("home.");
         })
      .catch(error => {
        console.log(error);
      });
  }




  	
  const { CurrencySymbol } = GlobalSet();
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
    useEffect(()=>{
    	const auth_token = localStorage.getItem('auth_token');
    	const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/apps-profile',data)
       	 .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])

	let Email;
	let Username;
	let mobile_No;
	let name;
	let balance;
	let bonus;
	let affiliate_earning;
	let identity_st;
	if (user == "") {
		console.log(user);
	}else{

		Email=user.data.email;
		name=user.data.name;
		mobile_No=user.data.mobile_No;
		Username=user.data.username;
		balance=user.data.balance;
		bonus=user.data.bonus;
		affiliate_earning=user.data.affiliate_earning;
    identity_st=user.data.identity_status;

	}
		
	const options = {
	  settings: {
	    overlayColor: "#000000",
	  },
	};

	const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{Username}</h4>
                    <p>{t('pro_pg_username')}</p>
                  </div>


                <div className="col-xl-3 col-sm-4">
                {(identity_st===0) || identity_st===3 ? 
                <Link to="/identity-verification">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><span className="text-white">
                        <span style={{ color: '#db2a2a' }}> {
                            identity_st===0 ? t('unber_identy_var') : identity_st===1 ? " Verified"  : identity_st===2 ? " Submitted, (we will verify it within 4 hours) " : " Resubmit"
                         } </span>
                        </span> 
                  </Link>
                : 
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><span className="text-white">
                         <span style={{ color: 'green' }}> {
                            identity_st===0 ? " Unverified"  : identity_st===1 ? " Verified"  : identity_st===2 ? " Submitted, (we will verify it within 4 hours) " : " Resubmit"
                         } </span>
                        </span> 
                  </div>
                 }
                </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



						<div className="col-xl-12">
							<div className="row">

								<div className="col-xl-6 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
													  <path  d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z" transform="translate(-2 -6)" fill="var(--primary)"/>
													</svg>
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(balance).toFixed(4))}</h4>
													<p className="mb-0 text-nowrap">{t('pro_pg_curr_bal')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-xl-6 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
													  <path  d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z" transform="translate(-2 -6)" fill="var(--primary)"/>
													</svg>
												</span>
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(bonus).toFixed(4))}</h4>
													<p className="mb-0 text-nowrap">Bonus</p>
												</div>
											</div>
										</div>
									</div>
								</div>


							</div>
						</div>








     <div className="row">
        <div className="col-xl-4">

		</div>	
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
					<Tab.Container defaultActiveKey='About'>					
						<Nav as='ul' className="nav nav-tabs mb-4">
							<Nav.Item as='li'i className="nav-item">
								<Nav.Link to="#about-me"  eventKey='About'>{t('pro_pg_ab_me')}</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>

							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-personal-info">
									<h4 className="text-primary mb-4">
										{t('pro_pg_info')}
									</h4>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_name')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{name}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_username')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Username}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_mob_num')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{mobile_No}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_email')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>{Email}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_age')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>..</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">  {t('pro_pg_location')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span>..</span>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>	
					</Tab.Container>		
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
};

export default AppProfile;
