import React, { Fragment, useState , useEffect } from "react";
import axios from "axios";

import swal from "sweetalert";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Wizard = () => {
const [goSteps, setGoSteps] = useState(0);
const { t } = useTranslation();
 const [securityCk, setUser] = useState([]);
  useEffect(()=>{
      const auth_token = localStorage.getItem('auth_token');
      const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
      if (auth_token !== null) {
         axios.post('/security-home',data)
         .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
           })
           .catch(error=>{
             console.log(error)
         })
      }

    },[])

  let id;
  let identity_st;
  let mail_status;
  let sponsor_user_Id;
  let total_deposit;
  if (securityCk == "") {
    console.log(securityCk);
  }else{
    id=securityCk.data.id;
    identity_st=securityCk.data.identity_status;
    mail_status=securityCk.data.mail_status;
    total_deposit=securityCk.data.total_deposit;
    sponsor_user_Id=securityCk.data.sponsor_user_Id;

  }


	return (
		<Fragment>
      <div className="row">

        <div className="col-xl-12 col-xxl-12 col-lg-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">Welcome offer Stepper</h4>
            </div>
            <div className="card-body">
              <PerfectScrollbar
                style={{ height: "370px" }}
                id="DZ_W_TimeLine"
                className="widget-timeline dlab-scroll height370 ps ps--active-y"
              >
                <ul className="timeline">


                  <li>
                    <div className={`timeline-badge ${sponsor_user_Id === 1 ? "success" : "dark"}`}></div>
                    <Link
                      className="timeline-panel text-black"
                      to="/affiliate"
                    >
                      <span>Don't Forget to Use the Promo Code!</span>
                      <h6 className="mb-0">
                        Must use promo code{" "}
                      </h6>
                    </Link>
                  </li>

									<li>
									  <div className={`timeline-badge ${identity_st === 1 ? "success" : "dark"}`}></div>
									  <Link className="timeline-panel text-black" to="/security">
									    <span>Complete Your Profile for 100% Verification!</span>
									    <h6 className="mb-0">Profile 100% Verification.</h6>
									  </Link>
									</li>

                  <li>
                    <div className={`timeline-badge ${total_deposit === 0 ? "dark" : "success"}`}></div>
                    <Link
                      className="timeline-panel text-black"
                      to="/deposit"
                    >
                      <span>Deposit $1 to $100 and Start Now!</span>
                      <h6 className="mb-0">
                        Minimum deposit $1 - $100 equivalent{" "}
                      </h6>
                    </Link>
                  </li>

                  <li>
                    <div className="timeline-badge success"></div>
                    <Link
                      className="timeline-panel text-black"
                      to="/"
                    >
                      <span>Enjoy the bonus</span>
                      <h6 className="mb-0">
                        Claim and Enjoy Your Bonus!
                      </h6>
                    </Link>
                  </li>

                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </div>

      

    </div>
		</Fragment>
	);
};

export default Wizard;