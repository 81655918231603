import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import loading_img from "../../../images/profile/loading.gif";
import { GlobalSet } from '../global/GlobalProvider';
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
const Home = () => {
    const { changeBackground } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "dark", label: "Dark" });
    }, []);

   const { BaseUrl } = GlobalSet();
   const [salider, setSalider] = useState([]); 
   const [homeCasino, setCasino] = useState([]);
   const [homeTopSlider, TopSlider] = useState([]);
   const [homeitem, setHomeItem] = useState([]);   
   const { t } = useTranslation();
















  var casino_home = BaseUrl + "public/appssource/sports_icons/casino-home.png";
  var sports_home = BaseUrl + "public/appssource/sports_icons/sports-home.png";
  var political_bet = BaseUrl + "public/appssource/sports_icons/political-bet.png";
  var plan_crash = BaseUrl + "public/appssource/sports_icons/plan-crash.png";



/*.....product code........*/
  const [loading, setLoading] = useState(true);
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setSalider(response.data.slider)
         setCasino(response.data.home_casino)
         TopSlider(response.data.top_slider)
         setHomeItem(response.data.home_items)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);


    
//mobile odds option
  const casino_game_s = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
            },
        ],
  };








//Sports Exchange start
  const history = useHistory();
  const location = useLocation();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [renderer, setRenderer] = useState(null); // Store BTRenderer instance
  const sel_Language = localStorage.getItem('selectedLanguage');
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');

  const calculateBetSlipOffsetTop = () => {
    const width = window.innerWidth;
    if (768  > width) {
      return 65;
    }
    return 77; // Default for other screen sizes
  };
  useEffect(() => {
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
      language: sel_Language,
    };
    const loadBetTech = async () => {
      try {
        if (auth_token) {
          const response = await axios.post('/sportsbook', data);
          loadBetTechScript(response.data.data.token);
        } else {
          loadBetTechScript(null);
        }
      } catch (error) {
        console.error("Error loading BetTech script:", error);
      }
    };

    loadBetTech();

    return () => {
      // Cleanup script when the component unmounts
      const script = document.getElementById("btrenderer-script");
      if (script) {
        script.remove();
      }
    };
  }, [history]);

  const loadBetTechScript = (token) => {
    // Check if BTRenderer is already loaded in the window object
    if(token){
      if (window.BTRenderer) {
        setIsScriptLoaded(true);
        initializeBetTechRenderer(token);
        return;
      }
    }

    // Load script if BTRenderer is not present
    if (!document.getElementById("btrenderer-script")) {
      const script = document.createElement("script");
      //script.src = "https://ui.invisiblesport.com/bt-renderer.min.js";  // Test
      script.src = "https://slotediscover.sptpub.com/bt-renderer.min.js"; // Live
      script.async = true;
      script.defer = true;
      script.id = "btrenderer-script";
      script.onload = () => {
        setIsScriptLoaded(true);
        initializeBetTechRenderer(token);
      };
      document.head.appendChild(script);
    }
  };

    const initializeBetTechRenderer = (token) => {
      if (window.BTRenderer) {
        const btRendererInstance = new window.BTRenderer().initialize({
          //brand_id: "2423510806797881344", //Test
          brand_id: "2448549541360836616", //Live
          token: token || "",
          themeName: "demo-red-dark-card",
          lang: sel_Language,
          target: document.getElementById("bettech"),
          minFrameHeight: 700,
          betSlipOffsetTop: calculateBetSlipOffsetTop(),
          stickyTop:  calculateBetSlipOffsetTop(),
          betslipZIndex: 999,
          onRouteChange: function () {
            console.log("on route change", arguments);
          },
          onLogin: function () {
            history.push('?login=true');
          },
          onRegister: function () {
            swal("Warning", "Not enough funds in the account", "warning");
            history.push('/register');
          },
          onSessionRefresh: function () {
            // Handle session refresh logic
          },
          onRecharge: function () {
            swal("Warning", "Not enough funds in the account", "warning");
            history.push('/deposit');
          },
          onTokenExpired: onTokenExpired,
        });
        setRenderer(btRendererInstance);
      }
    };


  const onTokenExpired = () => {
    return new Promise(async (resolve, reject) => {
      try {
        // Define the data you want to send in the POST request
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          currency: selectedCurrency,
          language: sel_Language,
        };
        const response = await axios.post('/sportsbook', data);
        // Check if the response is successful
        if (response.status === 200) {
          console.log("Token refresh successful:", response.data.data.token);
          resolve(response.data.data.token);  // Resolve the promise with the new token
        } else {
          throw new Error("Failed to refresh token with status: " + response.status);
        }
      } catch (error) {
        console.error("There was an error refreshing the token:", error);
        reject(error);  // Reject the promise if an error occurs
      }
    });
  };

  // Handle URL query changes
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const btPathd = query.get('bt-path');
    if (btPathd && renderer) {
      renderer.updateOptions({ url: btPathd });
    }
  }, [location.search, renderer]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
    const query = new URLSearchParams(location.search);
    const btPathd = query.get('bt-path');
      if (!btPathd && location.pathname !==  "/") {
        console.log("kill kill kill kill:", btPathd);
        renderer.kill();
      } else if (renderer && renderer.kill) {
        console.log("no kill no kill no kill no kill:", btPathd);
      }
    });

    return () => {
      unlisten(); // Stop listening to route changes when the component unmounts
    };
  }, [history, renderer]);

// Sports Exchange end

  var casino_part = "";
  if(loading)
  {

     return <div className="d-flex justify-content-center align-items-center vh-100"><img src={loading_img} alt="" className="me-3 rounded" width={75} /></div>    
  }
  else
  {
  casino_part = homeCasino.map((ele)=>{
    return (
      <div  className="col-xl-1 col-xxl-2 col-lg-2 col-md-3 col-sm-4 col-4 int-col p-1">  
            <div key={ele.id}>
              <Link to={"/casino-game-get/"+ele.id+"/"+ele.slug} className="text-black user-name">

                  <div className="img-bx">
                      <img src={(ele.image)} alt="" className=" me-3 card-list-img w-100" width="130" />
                  </div>  
               </Link>
            </div>  
        </div>     
      )
     });
  }


return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">


  <div className="row">
            <Fragment>
                <Carousel className="p-0">
                  {salider.map((carousel, i) => (
                    <Carousel.Item key={i}>
                      <img
                        src={BaseUrl+(carousel.img_link)}
                        className='d-block w-100'
                        alt={`Slide ${i + 1}`}
                        style={{ borderRadius: '10px' }}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel> 


            </Fragment> 
                  </div>
      
  <div className="row">
            <Fragment>
              <Col xl="9" className="p-0" >



                <Col className="m-2 ">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12" style={{paddingRight: '20px'}}>
                      <div className="row">


                        <div className="col-xl-3 col-lg-3 col-xxl-3 col-md-3 col-3 p-1">
                         <Link to={"home-game/all-game"} className="text-black user-name">
                          <div className="home_card">
                            <div className="card-header border-0 pb-0">
                              <div className="clearfix mb-1" style={{margin : '0px auto'}}>
                                <h3 className="card-title">Casino</h3>
                              </div>
                            </div>
                            <div className="text-center"> 
                             <img src={casino_home} alt="Casino" style={{ width: '145px'}} className="me-3 card-list-img pb-3 img-fluid" />
                            </div>
                          </div>
                         </Link>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-xxl-3 col-md-3 col-3 p-1">
                         <Link to={"sportsbook"} className="text-black user-name">
                          <div className="home_card">
                            <div className="card-header border-0 pb-0">
                              <div className="clearfix mb-1" style={{margin : '0px auto'}}>
                                <h3 className="card-title">Sports</h3>
                              </div>
                            </div>
                            <div className="text-center"> 
                             <img src={sports_home} alt="Sports" style={{ width: '145px'}} className="me-3 card-list-img pb-3 img-fluid" />
                            </div>
                          </div>
                         </Link>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-xxl-3 col-md-3 col-3 p-1">
                         <Link to={"sportsbook?bt-path=%2Fspecials-18"} className="text-black user-name">
                          <div className="home_card">
                            <div className="card-header border-0 pb-0">
                              <div className="clearfix mb-1" style={{margin : '0px auto'}}>
                                <h3 className="card-title">Political</h3>
                              </div>
                            </div>
                            <div className="text-center"> 
                             <img src={political_bet} alt="Political" style={{ width: '145px'}} className="me-3 card-list-img pb-3 img-fluid" />
                            </div>
                          </div>
                         </Link>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-xxl-3 col-md-3 col-3 p-1">
                         <Link to={"home-game/crash"} className="text-black user-name">
                          <div className="home_card">
                            <div className="card-header border-0 pb-0">
                              <div className="clearfix mb-1" style={{margin : '0px auto'}}>
                                <h3 className="card-title">Crash</h3>
                              </div>
                            </div>
                            <div className="text-center"> 
                             <img src={plan_crash} alt="Crash" style={{ width: '145px'}} className="me-3 card-list-img pb-3 img-fluid" />
                            </div>
                          </div>
                         </Link>
                        </div>

                      </div>
                    </div>
                  </div>
                 </Col>



                  <div className="col-12 mt-3">
                    <div className="card mb-2">
                      <div className="pt-1 pb-1">
                        <Slider className="front-view-slider owl-carousel owl-carousel owl-loaded owl-drag owl-dot" {...casino_game_s}>        
                        {homeTopSlider.map((ele)=>(
                          <div  className="p-1">  
                                <div key={ele.id}>
                                  <Link to={"/casino-game-get/"+ele.id+"/"+ele.slug} className="text-black user-name">

                                      <div className="img-bx">
                                          <img src={(ele.image)} alt="" className=" me-3 card-list-img w-100" width="80" />
                                      </div>  
                                   </Link>
                                </div>  
                            </div>  
                          ))}
                        </Slider>
                      </div>  
                    </div>  
                  </div>


              {/*
               <div className="App">
                  <div className="table-responsive">
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                           style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}
                        >

                          <tbody>
                            <tr className="odd" role="row">
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('All Game')}</Link></td>
                              <td> <Link to={"/home-game/live-casino"} className="text-black">{t('Live Casino')}</Link></td>
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('Table Games')}</Link></td>
                              <td> <Link to={"/home-game/jackpot-slots"} className="text-black">{t('Slots')}</Link></td>
                              <td> <Link to={"/home-game/poker"} className="text-black">{t('Poker')}</Link></td>
                              <td> <Link to={"/home-game/jackpot-slots"} className="text-black">{t('Table Games')}</Link></td>
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('Provider')}</Link></td>
                              <td> <Link to={"/home-game/all-game"} className="text-black">{t('Popular')}</Link></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                </div>*/}




              </Col>

              <Col xl="3" className="pl-1 mt-2" >
                <div className="row">
                 {homeitem.map((ele)=>(
                    <div  className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-4 int-col p-1">  
                          <div key={ele.id}>
                            <Link to={ele.link} className="text-black user-name">

                                <div className="img-bx">
                                    <img src={BaseUrl+(ele.img_link)} alt="" className=" me-3 card-list-img w-100" width="130" />
                                </div>  
                             </Link>
                          </div>  
                      </div> 
                  ))}
                </div>
              </Col>



{/*              <Col xl="12" className="p-0" >
                <Card>
                  <Card.Body className="p-2" >
                    <div className="form-inline">
                      <div className="row">
                        {casino_part}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>*/}


              <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12" style={{padding: '2px'}}>
                <div className="row">
                
                    <div className="col-xl-12 col-lg-12 col-xxl-12 col-md-12 col-12 p-1">                      
                    <div id="bettech"> </div>
                  </div>  

               </div>  
              </div>

          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;